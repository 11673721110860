import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import magento1to2 from "../images/magento1_magento2.png"

import migration_diff_platform from "../images/magento/magento_platform.png"
import Process__Step_Sr from "../images/process-step.jpg"
import Process__Step_Sr2 from "../images/magento/magento_dev.png"
import Process__Step_Sr3 from "../images/shopify/def_approach.png"
import ATG_Sr from "../images/magento/store.png"
import Magento2_build from "../images/magento/magento2_build.jpg"
import migration2 from "../images/magento/magento1_to2.jpg"
import Magento_performance from "../images/magento/magento_optimization.jpg"
import Magento_custom from "../images/magento/magento_custom.jpg"
import Magento_Audit from "../images/magento/magento_audit.png"
import {Helmet} from "react-helmet/es/Helmet"
import { LazyLoadImage } from 'react-lazy-load-image-component'

import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'

export default class Container extends Component {
  
  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Contact Us | Get in Touch with Cueblocks </title>
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
          <meta charSet="utf-8"/>
          <link rel="canonical" href="https://www.cueblocks.com/contact.php"/>
          <meta name="description"
                content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
          <meta property="og:site_name" content="Cueblocks"/>
          <meta property="fb:app_id" content="289086684439915"/>
          <meta property="og:url" content="https://www.cueblocks.com/contact.php"/>
          <meta property="og:title" content="Contact Us | Get in Touch with Cueblocks"/>
          <meta property="og:description"
                content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
          <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:description"
                content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
          <meta name="twitter:title" content="Contact Us | Get in Touch with Cueblocks"/>
          <meta name="twitter:site" content="@cueblocks"/>
          <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <article className="static_page">
          <section className="ser_sec_row  creative_design_page ">
            <div className="container">
              <div className="ser_sec_row_lt wdt-40 pd_right_40">
                <h2 className="heading_main">Magento</h2>
                <div className="ser_sec_row_lt_text">
                  <p>
                    The fierce vying in the eCommerce industry calls for an intuitive & interactive eStore that can take on the diverse business challenges
                     and provide tailored solutions to each of your unique goals. Using the wide array of functionalities offered by Magento, our team
                      of certified developers meticulously analyzes every aspect of your site, from the extensions down to the very code, examining 
                      even the smallest bottleneck concern. Blending in-house & industry best practices, we provide native Magento implementations
                       pertaining to your business’s requirements, running thorough quality checks at every stage. 
                  </p>
                </div>
              </div>
              <div className="ser_sec_row_rt wdt-60 pd_left_40 text-center">
                <img
                  src={magento1to2}
                  alt="Cue Blocks"
                  className="img-responsive"
                />
              </div>
              <div className="border-light"> </div>
            </div>
          </section>

          <section className=" our-process">
            <div className="container">
              <div className="main-heading text-center">
                <h2 className="heading_main">our approach</h2>
              </div>
              <div className="process-steps">
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr2}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>DEVELOPMENT ENVIRONMENT</h3>
                    <p>
                      To ensure that a properly vetted code makes it to your live site, we use reliable Version Control Systems and Staging Environments 
                      to give you a high-quality website that can easily pass quality checks of a global standard. 
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr3}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>DEFINED APPROACH</h3>
                    <p>
                      Our team of proficient experts devises a focussed strategy to get your website up and running as fluidly as possible because, at CueBlocks,
                       streamlined communication is essential for the smooth development of any website.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>CONSULTATIVE APPROACH</h3>
                    <p>
                      Our ‘Thinking out of the Box’ abilities put together with our experience in the eCommerce industry helps us to give you the right 
                      guidance for your website, blending the basic concepts with what’s trending. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    
                    <LazyLoadImage
                      src={Magento2_build}
                      effect="blur"
                      delayTime="0"
                      // placeholderSrc={Magento_performance}
                      
                      alt="Cue Blocks"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    MAGENTO 2 BUILD
                    <br /> FROM SCRATCH
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Being one of the first few agencies to be listed as a Magento 2 trained team, we can confidently say that you can leave all your website-related 
                      worries to us. We know Magento like the back of our hands and can help you get started in a jiffy.
                       <br />
                      <br />
                      Get in touch with us to know how we can help.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics  content-both">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <h2 className="heading_main">
                    MIGRATION FROM MAGENTO 1 TO MAGENTO 2

                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Have you heard that Magento has announced the ‘end of support’ for its version 1.x from June 2020?  <br />
                      <br />
                      If not then we are here to help. We recommend migrating your website to a more powerful, robust, and secure successor , Magento 2.
                      <br />
                      <br />
                      <Link to="/contact" className="simple_anchor blk"> Drop us a line to know more about Magento 2.</Link>
                    </p>
                  </div>
                
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    MIGRATION TO MAGENTO 2 FROM A DIFFERENT PLATFORM
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Every ecommerce brand deserves a platform that not only adapts to the growing needs of an e-store but also acts as an appendage to it, 
                      making it more user as well as admin-friendly.
                       <br />
                      <br />
                     Magento 2 offers this and much more, making it the most sought-after platform in the ecommerce industry right now.
                     <br />
                      <br />
                      <Link to="/magento-1-to-magento-2-migration-service/" className="simple_anchor blk">Need help migrating?</Link>
                   
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className=" motion-graphics  magento_audit">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_right_40">
                  <h2 className="heading_main">MAGENTO AUDIT</h2>
                  <div className="ser_sec_row_lt_text ">
                    <p>
                      To gauge the possibilities for your website, we conduct an audit that focuses on analyzing all the options available at our disposal to help
                       create an enduring e-store for your business. The practice of coding serves as building blocks for a website, especially when you are building from
                        scratch. 
                        <br />
                      <br />
                        To create an eStore for your brand, we formulate the most ideal strategies and tools that facilitate the ease of access for your customers.
                    </p>
                  </div>
                </div>
                <div className="ser_sec_row_lt pd_left_40 hide-mob">
                  <figure>
                    <LazyLoadImage
                      src={Magento_Audit}
                      effect="blur"
                      alt="Cue Blocks"
                      className="img-responsive"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  
                  <figure>
                    <span className="highlight-span">MAGENTO/WOMANSWORK</span>
                    <LazyLoadImage
                      src={Magento_performance}
                      effect="blur"
                      alt="Cue Blocks"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_20">
                
                  <h2 className="heading_main">
                    MAGENTO PERFORMANCE AUDIT AND OPTIMIZATION
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Optimizing the performance of a website is the easiest way to ensure an efficient and fast-loading e-store. Our Magento 2 certified developers 
                      use the best in the industry optimizing techniques to create an e-store for you that opens with just a click of a button. 
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics right-img fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_20 right">
                  <figure>
                    <span className="highlight-span">MAGENTO/petdooors</span>
                    <img
                      src={Magento_custom}
                      alt="Magento2 Migration"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_40">
                  <h2 className="heading_main">MAGENTO CUSTOM MODULE BUILD</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Magento offers a plethora of customizable features that are required by any eCommerce store. Then, there are pre-built extensions 
                      for the features that are not available in Magento’s default settings. If you are still looking for a custom module, our team of adept developers 
                      can always create one for you.
                       <br />
                      <br />
                      Not able to find the right module for your site? Send us a
                      message.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="products_block conpetulatization ">
            <div className="container">
              <div className="all-grows-block" id="crt-degn">
                <div className="ser_sec_row_lt  wdt-40">
                  <h2 className="heading_main">
                    BUILDING ON TOP OF AN EXISTING STORE
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Breaking the norms sounds good, right? What if you could customize your website exactly the way you want? Even better! If you are looking 
                      for a change or addition to your existing e-store, we've got the expertise. With our adept knowledge and skills, we create bespoke functionalities 
                      for your e-store without disrupting your current website.
                      <br />
                     
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
                <div className="ser_sec_row_rt  wdt-60 hide-mob">
                  <img
                    src={ATG_Sr}
                    alt="Cue Blocks"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </section>
        </article>
      </Layout>
    )
  }
}
